import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAccountId, setClientArn, setComputeCosts } from 'src/redux/slices/awsAccountSlice';
import { setOrgDetails } from 'src/redux/slices/orgSlice';
import { orgService } from 'src/services/orgService';
import { awsService } from 'src/services/awsService';

const useEnsureData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, isAuthenticated } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const { accountId, clientArn, computeCosts } = useSelector((state) => state.awsAccount);
    const { orgId, rootUserId, rootUserEmail } = useSelector((state) => state.org);

    // Check for all needed data to be loaded
    const isDataLoaded = accountId && clientArn &&
        computeCosts && computeCosts.cost_last_month &&
        orgId && rootUserId && rootUserEmail;

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const loadData = useCallback(async () => {
        if (isDataLoaded || !isAuthenticated) {
            return;
        }

        setIsLoading(true);
        try {
            setLoadingMessage('Authenticating...');
            await delay(1000);

            setLoadingMessage('Fetching data...');
            const orgData = await orgService.getOrganization(token);
            if (!orgData || orgData.length === 0 || !orgData[0].id || !orgData[0].root_user || !orgData[0].root_email) {
                setLoadingMessage('Organization data is incomplete or missing.');
                await delay(2000);
                navigate('/welcome');
                return;
            } else {
                dispatch(setOrgDetails({
                    id: orgData[0].id,
                    root_user: orgData[0].root_user,
                    root_email: orgData[0].root_email
                }));
            }

            const accountData = orgData[0].aws_accounts;
            if (!accountData || accountData.length === 0 || !accountData[0].aws_account_id || !accountData[0].client_arn) {
                setLoadingMessage('AWS account data is incomplete or missing.');
                await delay(2000);
                navigate('/awsaccount');
                return;
            } else {
                dispatch(setAccountId(accountData[0].aws_account_id));
                dispatch(setClientArn(accountData[0].client_arn));
            }

            setLoadingMessage('Fetching compute costs...');
            const computeCosts = await awsService.fetchComputeCost(accountData[0].aws_account_id, token);
            if (computeCosts) {
                dispatch(setComputeCosts(computeCosts));
            }

            setLoadingMessage('All data loaded successfully.');
            await delay(1000);
        } catch (error) {
            setLoadingMessage(`Failed to fetch initial data. Please try again later.`);
            await delay(2000);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, isDataLoaded, token, navigate, dispatch]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return { isLoading, loadingMessage };
};

export default useEnsureData;
