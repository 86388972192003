import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { awsService } from "src/services/awsService";
import {
    Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel, Button
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';


const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'maintenance_window', numeric: false, label: 'Maintenance Windows' },
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            fontWeight: 'bold',
                            padding: '8px',
                            backgroundColor: '#f0f0f0',
                            width: headCell.id === 'name' ? '25%' : '75%'
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function GroupsList() {
    const [groups, setGroups] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMaintenanceId, setSelectedMaintenanceId] = useState(null);

    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const data = await awsService.fetchAwsmoGroups(token);
                setGroups(data);
            } catch (error) {
                console.error("Failed to fetch groups:", error);
            }
        };
        fetchGroups();
    }, [token]);

    const handleMaintenanceWindowClick = (id) => {
        navigate('/maintenance', { state: { highlightedId: id } });
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredAndSortedGroups = groups
        .filter(group => {
            if (!searchTerm) return true;
            return Object.keys(group).some(key => {
                const value = group[key].toString().toLowerCase();
                if (key === 'maintenance_windows') {
                    return group.maintenance_windows.some(id => id.toString().toLowerCase().includes(searchTerm.toLowerCase()));
                }
                return value.includes(searchTerm.toLowerCase());
            });
        })
        .sort((a, b) => {
            if (orderBy === 'name') {
                return (order === 'asc' ? 1 : -1) * a.name.localeCompare(b.name);
            }
        });

    return (
        <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple">
            <div className='row'>
                <div className='col-12'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem' }}>
                        <div style={{ fontSize: '1.125rem', fontWeight: 'bold', marginBottom: '8px' }}>List of Groups</div>
                        <div className='d-flex'>
                            <div className='search-wrap'>
                                <input
                                    type="text"
                                    className='search-input'
                                    placeholder='Find instance groups by attributes'
                                    onChange={handleSearchChange}
                                    style={{ marginRight: '50px', marginBottom: '10px' }}
                                />
                                <button type='button' className='search-btn'>
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='dash-table'>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="groups table" sx={{ minWidth: 750 }}>
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {filteredAndSortedGroups.map((group) => (
                                                <TableRow hover key={group.id}>
                                                    <TableCell component="th" scope="row" align="center" style={{ width: '25%' }}>
                                                        <Link to={`/groups/${group.id}`} style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                                                            onMouseOver={({ target }) => target.style.textDecoration = 'underline'}
                                                            onMouseOut={({ target }) => target.style.textDecoration = 'none'}>
                                                            {group.name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: '75%' }}>
                                                        {group.maintenance_windows.map(id => (
                                                            <Button
                                                                key={id}
                                                                onClick={() => handleMaintenanceWindowClick(id)}
                                                                variant="outlined"
                                                                style={{ margin: '2px' }}
                                                            >
                                                                {id}
                                                            </Button>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupsList;
