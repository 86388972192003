import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { parse, format, subDays, subMonths, subYears, isWithinInterval } from 'date-fns';
import 'src/style/tooltip.css';
import annotationPlugin from 'chartjs-plugin-annotation';
import { awsService } from 'src/services/awsService';

// Register necessary Chart.js components
ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
ChartJS.register(annotationPlugin);

export default function Cost({ awsmoGroupId, token, loadBalancerDetails, granularity }) {
    const [costData, setCostData] = useState([]);

    useEffect(() => {
        if (awsmoGroupId) {
            fetchCostData();
        }
    }, [awsmoGroupId, granularity]);

    // Fetch cost data from AWS
    const fetchCostData = async () => {
        try {
            const data = await awsService.fetchAwsmoGroupCost(awsmoGroupId, token);
            // Sort data by timestamp
            data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            const processedCostData = processCostData(data);
            setCostData(processedCostData);
        } catch (error) {
            console.error('Error fetching cost data:', error);
        }
    };



    // Process the raw cost data based on selected granularity
    const processCostData = (data) => {
        const endDate = new Date();
        let startDate;

        switch (granularity) {
            case '7d':
                startDate = subDays(endDate, 7);
                break;
            case '1m':
                startDate = subMonths(endDate, 1);
                break;
            case '1y':
                startDate = subYears(endDate, 1);
                break;
            default:
                startDate = subDays(endDate, 7);
        }

        const filteredData = data.filter(item =>
            isWithinInterval(new Date(item.timestamp), { start: startDate, end: endDate })
        );

        if (granularity === '7d') {
            return filteredData.map(item => ({
                x: new Date(item.timestamp),
                y: item.cost,
                details: item.data.cost_items
            }));
        } else {
            const groupedByDate = filteredData.reduce((acc, item) => {
                const date = format(new Date(item.timestamp), 'yyyy-MM-dd');
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(item);
                return acc;
            }, {});

            return Object.entries(groupedByDate).map(([date, items]) => {
                const totalCost = items.reduce((sum, item) => sum + item.cost, 0);
                const aggregatedDetails = items.flatMap(item => item.data.cost_items)
                    .reduce((acc, curr) => {
                        const key = `${curr.instance}-${curr.type}`;
                        if (!acc[key]) {
                            acc[key] = { ...curr, quantity: 0, cost: 0 };
                        }
                        acc[key].quantity += curr.quantity;
                        acc[key].cost += curr.cost;
                        return acc;
                    }, {});

                return {
                    x: new Date(date),
                    y: totalCost,
                    details: Object.values(aggregatedDetails)
                };
            });
        }
    };

    // Get the date range for the x-axis
    const getDataRange = () => {
        if (costData.length === 0) return [new Date(), new Date()];
        const dates = costData.map(d => d.x);
        return [new Date(Math.min(...dates)), new Date(Math.max(...dates))];
    };

    // Get the maximum cost value for the y-axis
    const getMaxValue = () => Math.max(...costData.map(d => d.y));

    const [minDate, maxDate] = getDataRange();

    // Chart data configuration
    const chartData = {
        datasets: [{
            label: 'Total Cost',
            data: costData,
            borderColor: 'rgb(0, 200, 100)',
            backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) return null;
                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, 'rgba(0, 200, 100, 0)');
                gradient.addColorStop(1, 'rgba(0, 200, 100, 0.3)');
                return gradient;
            },
            tension: 0.4,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointBackgroundColor: 'rgb(0, 200, 100)',
            fill: true,
        }]
    };
    const verticalLinePlugin = {
        id: 'verticalLine',
        afterDraw: (chart, args, options) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
                const activePoint = chart.tooltip._active[0];
                const ctx = chart.ctx;
                const x = activePoint.element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;

                // Draw vertical line
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
                ctx.setLineDash([5, 5]);
                ctx.stroke();
                ctx.restore();
            }
        }
    };

    // Register the plugin
    ChartJS.register(verticalLinePlugin);

    // Chart options configuration
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: false,
                external: function (context) {
                    let tooltipEl = document.getElementById('chartjs-tooltip');
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    tooltipEl.classList.add('above');

                    const dateFormat = granularity === '7d' ? 'MMM dd, yyyy HH:mm' : 'MMM dd, yyyy';
                    const dataPoint = tooltipModel.dataPoints[0].raw;
                    const date = parse(tooltipModel.title[0], "MMM dd, yyyy, h:mm:ss a", new Date());

                    let innerHtml = `
                        <div>${format(date, dateFormat)}</div>
                        <div>Total Cost: $${dataPoint.y.toFixed(2)} / hr</div>
                        <table class="instance-costs">
                            <thead>
                                <tr>
                                    <th>Instance</th>
                                    <th>Type</th>
                                    <th>Quantity</th>
                                    <th>Cost per hour</th>
                                </tr>
                            </thead>
                            <tbody>
                    `;
                    dataPoint.details.forEach(detail => {
                        innerHtml += `
                            <tr>
                                <td>${detail.instance}</td>
                                <td>${detail.type}</td>
                                <td>${detail.quantity}</td>
                                <td>$${detail.cost.toFixed(4)}</td>
                            </tr>
                        `;
                    });
                    innerHtml += `
                            </tbody>
                        </table>
                    `;

                    tooltipEl.innerHTML = innerHtml;
                    const yAxis = context.chart.scales.y;

                    const yRange = yAxis.max - yAxis.min;
                    const upperPathValue = yAxis.min + (yRange * 0.9); // 90% of Y-axis
                    const lowerPathValue = yAxis.min + (yRange * 0.2); // 20% of Y-axis
                    const upperThreshold = yAxis.min + (yRange * 0.8); // 80% of Y-axis

                    const upperPathY = yAxis.getPixelForValue(upperPathValue);
                    const lowerPathY = yAxis.getPixelForValue(lowerPathValue);

                    // Determine which path to use based on the data point value
                    let tooltipY;
                    const dataValue = dataPoint.y;
                    if (dataValue > upperThreshold) {
                        tooltipY = lowerPathY; // Use lower path for high values
                    } else {
                        tooltipY = upperPathY; // Use upper path for all other values
                    }

                    const position = context.chart.canvas.getBoundingClientRect();
                    const bodyFont = tooltipModel.options.bodyFont;

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipY + 'px';
                    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
                    tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.transform = 'translate(-50%, -50%)'; // Center the tooltip
                }
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: granularity === '7d' ? 'day' : 'month',
                    displayFormats: {
                        day: 'MMM dd',
                        month: 'MMM yyyy'
                    }
                },
                title: {
                    display: true,
                    text: granularity === '7d' ? 'Date' : 'Month'
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: granularity === '7d' ? 7 : 12
                },
                min: minDate,
                max: maxDate
            },
            y: {
                title: {
                    display: true,
                    text: 'Cost per hour (USD)'
                },
                ticks: {
                    callback: (value) => `$ ${value}`
                },
                suggestedMin: 0,
                suggestedMax: getMaxValue() * 1.1
            }
        },
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y',
                    value: function (context) {
                        const yAxis = context.chart.scales.y;
                        const yRange = yAxis.max - yAxis.min;
                        return yAxis.min + (yRange * 0.8); // 80% of Y-axis
                    },
                    borderColor: 'black',
                    borderWidth: 1,
                    drawTime: 'afterDatasetsDraw'
                },
                {
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y',
                    value: function (context) {
                        const yAxis = context.chart.scales.y;
                        const yRange = yAxis.max - yAxis.min;
                        return yAxis.min + (yRange * 0.2); // 20% of Y-axis
                    },
                    borderColor: 'red',
                    borderWidth: 1,
                    drawTime: 'afterDatasetsDraw'
                }
            ]
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 3, height: 'calc(100vh - 300px)' }}>
            <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: 1, p: 2 }}>
                <Line data={chartData} options={chartOptions} />
            </Box>
        </Box>
    );
}