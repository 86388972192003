import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, Paper, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { awsService } from 'src/services/awsService';
import Cost from './graphs/Cost';
import Utilization from './graphs/Utilization';

export default function Reports() {
    // State variables
    const [loadBalancers, setLoadBalancers] = useState([]);
    const [selectedLoadBalancer, setSelectedLoadBalancer] = useState('');
    const [awsmoGroupId, setAwsmoGroupId] = useState(null);
    const [loadBalancerDetails, setLoadBalancerDetails] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState('cost');
    const [granularity, setGranularity] = useState('7d'); // New state for granularity

    // Redux selectors
    const token = useSelector((state) => state.user.token);
    const accountId = useSelector((state) => state.awsAccount.accountId);

    // Fetch load balancers on component mount
    useEffect(() => {
        fetchLoadBalancers();
    }, []);

    // Fetch load balancer details when selection changes
    useEffect(() => {
        if (selectedLoadBalancer) {
            fetchLoadBalancerDetails(selectedLoadBalancer);
        }
    }, [selectedLoadBalancer]);

    // Fetch load balancers
    const fetchLoadBalancers = async () => {
        try {
            const data = await awsService.fetchLoadBalancers(token);
            setLoadBalancers(data);
            if (data.length > 0) {
                setSelectedLoadBalancer(data[0].id);
                setAwsmoGroupId(data[0].awsmo_group_id);
            }
        } catch (error) {
            console.error('Error fetching load balancers:', error);
        }
    };

    // Fetch load balancer details
    const fetchLoadBalancerDetails = async (id) => {
        try {
            const data = await awsService.fetchLoadBalancerById(id, token);
            setLoadBalancerDetails(data);

            const selectedLoadBalancer = loadBalancers.find(lb => lb.id === id);
            if (selectedLoadBalancer) {
                setAwsmoGroupId(selectedLoadBalancer.awsmo_group_id);
            }
        } catch (error) {
            console.error('Error fetching load balancer details:', error);
        }
    };

    return (
        <Box className='margin-top-dash font-open' sx={{ overflowX: 'auto' }}>
            <Box className='h-scroller rightContainer'>
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
                    <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
                        {/* Load Balancer selection */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6" sx={{ mr: 2, whiteSpace: 'nowrap' }}>Load Balancer ARN :</Typography>
                            <Box sx={{ width: '70%' }}>
                                <Select
                                    value={selectedLoadBalancer}
                                    onChange={(e) => setSelectedLoadBalancer(e.target.value)}
                                    displayEmpty
                                    fullWidth
                                >
                                    {loadBalancers.map((lb) => (
                                        <MenuItem key={lb.id} value={lb.id}>{lb.LoadBalancerArn}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>

                        {/* Metric selection */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <ToggleButtonGroup
                                value={selectedMetric}
                                exclusive
                                onChange={(e, newMetric) => {
                                    if (newMetric) setSelectedMetric(newMetric);
                                }}
                                aria-label="metric selection"
                            >
                                <ToggleButton value="cost" aria-label="monthly cost">
                                    Cost
                                </ToggleButton>
                                <ToggleButton value="utilization" aria-label="monthly utilization">
                                    Utilization
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {/* Granularity selection */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <ToggleButtonGroup
                                value={granularity}
                                exclusive
                                onChange={(e, newGranularity) => {
                                    if (newGranularity) setGranularity(newGranularity);
                                }}
                                aria-label="granularity selection"
                            >
                                <ToggleButton value="7d" aria-label="last 7 days">
                                    Last 7 days
                                </ToggleButton>
                                <ToggleButton value="1m" aria-label="last 1 month">
                                    Last 1 Month
                                </ToggleButton>
                                <ToggleButton value="1y" aria-label="last 1 year">
                                    Last 1 Year
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {/* Render either Cost or Utilization based on selectedMetric */}
                        {selectedMetric === 'cost' ? (
                            <Cost
                                awsmoGroupId={awsmoGroupId}
                                token={token}
                                loadBalancerDetails={loadBalancerDetails}
                                granularity={granularity}
                            />
                        ) : (
                            <Utilization
                                accountId={accountId}
                                token={token}
                                loadBalancerDetails={loadBalancerDetails}
                                granularity={granularity}
                            />
                        )}
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}